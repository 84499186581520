import Trickz from "./images/trickz3.png";
import Friday from "./images/friday2.png";
import Winnerz from "./images/winnerz2.png";
import Wisho from "./images/wisho.webp";
// import Energy from "./images/energy.png";
import Powerup from "./images/powerup.png";
import Joy from "./images/joy.jpg";
import Kanuuna from "./images/kaunuunav2.png";
import Highroller from "./images/highroller.jpg";
import Lataamo from "./images/lataamo.png";
import Igni from "./images/igni.png";
import Lumi from "./images/lumi.png";
import SGCasino from "./images/sgcasino.png";
import Wazamba from "./images/wazamba2.png";
import Huikee from "./images/huikee.png";
import Rapid from "./images/Rapid.png";
import Nitro from "./images/Nitro.png";
import NeonVegas from "./images/NeonVegas.png";
import Palm from "./images/palmslots.png";
import Ursa from "./images/ursa.png";
import Slotbox from "./images/slotbox2.png";
import Spinrollz from "./images/spinrollz.png";
import Gomblingo from "./images/gomblingo.png";
import Cazimbo from "./images/cazimbo.png";
import Vauhdikas from "./images/vauhdikas.png";
import Casimba from "./images/casimba.png";
import Pelikaani from "./images/pelikaani.png";
import Wildz from "./images/wildz.png";
import Chipz from "./images/chipz.png";
import Flappy from "./images/flappy.png";
import Frumzi from "./images/frumzi.png";
import Nova from "./images/novajackpot.png";
import None from "./images/n1casino.png";

const bonuses = [
  // {
  //   name: "energy",
  //   type: "bonus",
  //   img: Energy,
  //   link: "https://charity.energy.partners/redirect.aspx?pid=49783&bid=5799",
  //   payNplay: true,
  //   license: "MGA",
  //   highlight: true,
  //   perks: {
  //     bonus: {
  //       maxSum: 300,
  //       percentage: 100,
  //     },
  //     freeSpins: {
  //       value: 300,
  //     },
  //     wager: { value: 30 },
  //   },
  // },
  {
    name: "novajackpot",
    type: "bonus",
    img: Nova,
    link: "https://hub.buzzaffiliates.com/visit/?bta=35919&brand=novajackpot",
    license: "Curacao",
    highlight: true,
    nonSticky: true,
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      freeSpins: {
        value: 200,
      },
      wager: { value: 35 },
    },
  },
  {
    name: "vauhdikas",
    type: "bonus",
    img: Vauhdikas,
    link: "https://afftrackcf.21.partners/C.ashx?btag=a_17893b_1099c_&affid=4224&siteid=17893&adid=1099&c=",
    license: "MGA",
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 300,
        percentage: 100,
      },
      freeSpins: {
        value: 100,
      },
      wager: {
        value: 40,
        condition: "CODE: VAUH",
      },
    },
  },
  {
    name: "n1",
    type: "bonus",
    img: None,
    link: "http://url1009.softswiss-service.com/ls/click?upn=u001.Ygo2sEeJ8-2Fx-2BG91Z6c7jCE1ZER3N-2Fx1y7KoBAZnKUcAsO9CVaLVVdUcgUor-2Bip9zB3rj_-2FZ2voozZI-2FoRtG4SqHh5mTv3j02offQC97bPOAIGehwBhM7lgW2YZVD8lk2CdUJaTdONAb0CSNmOiTMFTg6HoP-2B6rx0eV-2BfMjbViRiIeq6unc18gI-2FgZJqd7-2BOaDcyMKOzkSlDZlSD3Y8tzWkkY-2B7FQewQQIS3hBAKHSzvqKFlTIPRhgcHYHUAglZUxRaEe5BUlfmUvzl33DJz6XutDgPHYq3ey9ccENRNQ-2BrREwMhs-3D",
    license: "MGA",
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 1000,
        percentage: 100,
      },
      freeSpins: {
        value: 150,
      },
      wager: {
        value: 50,
      },
    },
  },
  {
    name: "frumzi",
    type: "bonus",
    img: Frumzi,
    link: "https://frm.servclick1move.com/?mid=232234_1382629",
    license: "Curacao",
    nonSticky: true,
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      freeSpins: {
        value: 200,
      },
      wager: {
        value: 35,
      },
    },
  },
  {
    name: "slotbox",
    type: "bonus",
    img: Slotbox,
    link: "https://bonus.slotbox.com/fi-nor-p-8",
    license: "Estonia",
    perks: {
      bonus: {
        maxSum: 100,
        percentage: 100,
      },
      freeSpins: {
        value: 100,
      },
    },
  },
  {
    name: "casibma",
    type: "bonus",
    img: Casimba,
    link: "https://ivyaffsolutions.com/C.ashx?btag=a_32870b_861c_&affid=6452&siteid=32870&adid=861&c=",
    license: "MGA",
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 5000,
        percentage: 100,
      },
      freeSpins: {
        value: 50,
      },
      wager: { value: 35 },
    },
  },
  {
    name: "pelikaani",
    type: "bonus",
    img: Pelikaani,
    link: "https://go.moonrocketaffiliates.com/visit/?bta=35509&brand=pelikaani&utm_campaign=yangile",
    payNplay: true,
    license: "Estonia",
    perks: {
      freeSpins: {
        value: 1,
        condition: "Superspin",
      },
      wager: {
        value: 1,
      },
    },
  },
  {
    name: "ursa",
    type: "bonus",
    img: Ursa,
    link: "https://ivyaffsolutions.com/C.ashx?btag=a_35105b_848c_&affid=6452&siteid=35105&adid=848&c=",
    payNplay: true,
    license: "Isle of Man",
    perks: {
      bonus: {
        maxSum: 200,
        percentage: 100,
      },
      wager: { value: 40 },
      freeSpins: {
        value: 50,
      },
    },
  },
  {
    name: "wildz",
    type: "bonus",
    img: Wildz,
    link: "https://go.rootzaffiliate.com/visit/?bta=903434&nci=5988",
    payNplay: true,
    nonSticky: true,
    license: "MGA",
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      wager: { value: 35 },
      freeSpins: {
        value: 200,
      },
    },
  },
  {
    name: "chipz",
    type: "bonus",
    img: Chipz,
    link: "https://go.rootzaffiliate.com/visit/?bta=903434&nci=5931",
    payNplay: true,
    license: "MGA",
    perks: {
      freeSpins: {
        value: 100,
      },
    },
  },
  {
    name: "flappy",
    type: "bonus",
    img: Flappy,
    link: "https://record.winmaniacs.com/_a1BXbZV3uxxD3TEmsPWI0WNd7ZgqdRLk/1/",
    nonSticky: true,
    license: "Curacao",
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      wager: { value: 50 },
      freeSpins: {
        value: 50,
      },
    },
  },
  {
    name: "nitro",
    type: "bonus",
    img: Nitro,
    link: "https://afftracknc.21.partners/C.ashx?btag=a_17893b_944c_&affid=4224&siteid=17893&adid=944&c=",
    payNplay: true,
    license: "MGA",
    perks: {
      bonus: {
        maxSum: 300,
        percentage: 100,
      },
      wager: { value: 40, condition: "Code: NITRO" },
    },
  },
  {
    name: "rapid",
    type: "bonus",
    img: Rapid,
    link: "https://afftrackrc.21.partners/C.ashx?btag=a_17893b_1243c_&affid=4224&siteid=17893&adid=1243&c=",
    payNplay: true,
    license: "MGA",
    perks: {
      bonus: {
        maxSum: 300,
        percentage: 100,
      },
      wager: { value: 40, condition: "Code: RAPID" },
    },
  },
  {
    name: "neonvegas",
    type: "bonus",
    img: NeonVegas,
    link: "https://afftracknv.21.partners/C.ashx?btag=a_17893b_1241c_&affid=4224&siteid=17893&adid=1241&c=",
    payNplay: true,
    license: "MGA",
    perks: {
      bonus: {
        maxSum: 300,
        percentage: 100,
      },
      wager: { value: 40, condition: "Code: NEON" },
    },
  },
  {
    name: "cazimbo",
    type: "bonus",
    img: Cazimbo,
    link: "https://track.affilirise.com/visit/?bta=656506&nci=5395&afp=",
    payNplay: true,
    nonSticky: true,
    license: "PAGCOR",
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      freeSpins: {
        value: 200,
      },
      wager: { value: 35 },
    },
  },
  {
    name: "spinrollz",
    type: "bonus",
    img: Spinrollz,
    link: "https://track.affilirise.com/visit/?bta=656506&nci=5540&afp=",
    payNplay: true,
    nonSticky: true,
    license: "PAGCOR",
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      freeSpins: {
        value: 200,
      },
      wager: { value: 40 },
    },
  },
  {
    name: "palmslots",
    type: "bonus",
    img: Palm,
    link: "https://track.trafficflowpartners.com/visit/?bta=36641&nci=5372",
    license: "Curacao",
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      wager: { value: 35, condition: "Code: PSWEL200" },
      freeSpins: {
        value: 50,
      },
    },
  },
  {
    name: "igni",
    type: "bonus",
    img: Igni,
    link: "https://ivyaffsolutions.com/C.ashx?btag=a_33228b_826c_&affid=6452&siteid=33228&adid=826&c=",
    perks: {
      freeSpins: { value: 100 },
      bonus: { maxSum: 500, percentage: 100 },
      wager: { value: 40 },
    },
    highlight: true,
    payNplay: true,
    license: "MGA",
  },
  {
    name: "lumi",
    type: "bonus",
    img: Lumi,
    link: "https://ivyaffsolutions.com/C.ashx?btag=a_33229b_811c_&affid=6452&siteid=33229&adid=811&c=",
    perks: {
      freeSpins: { value: 75 },
      bonus: { maxSum: 2500, percentage: 100 },
      wager: { value: 40 },
    },
    payNplay: true,
    license: "MGA",
  },
  {
    name: "huikee",
    type: "bonus",
    img: Huikee,
    link: "https://ivyaffsolutions.com/C.ashx?btag=a_33964b_750c_&affid=6452&siteid=33964&adid=750&c=",
    perks: {
      bonus: { maxSum: 300, percentage: 100 },
      freeSpins: { value: 20 },
      wager: { value: 40 },
    },
    payNplay: true,
    license: "MGA",
  },
  {
    name: "highroller",
    type: "bonus",
    img: Highroller,
    link: "https://tinyurl.com/HighRollerSuomi",
    perks: {
      freeSpins: { value: 200, condition: "20€ Min. Deposit" },
      bonus: { maxSum: 500, percentage: 100 },
    },
  },
  {
    name: "kanuuna",
    type: "bonus",
    img: Kanuuna,
    link: "https://tinyurl.com/kanuunakassu",
    perks: {
      freeSpins: { value: 200 },
    },
  },
  {
    name: "gomblingo",
    type: "bonus",
    img: Gomblingo,
    link: "https://track.affilirise.com/visit/?bta=656506&nci=5477&afp=",
    license: "Curacao",
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 150,
      },
      freeSpins: {
        value: 200,
      },
      wager: { value: 40 },
    },
  },
  {
    name: "sgcasino",
    type: "bonus",
    img: SGCasino,
    link: "https://sgc.servclick1move.com/?mid=127597_905761",
    perks: {
      freeSpins: { value: 200 },
      wager: { value: 35 },
      bonus: { percentage: 100, maxSum: 500 },
    },
    license: "Curacao",
    payNplay: true,
  },
  {
    name: "wazamba",
    type: "bonus",
    img: Wazamba,
    link: "https://wzbw.servclick1move.com/?mid=127597_905745",
    perks: {
      freeSpins: { value: 200 },
      wager: { value: 35 },
      bonus: { percentage: 100, maxSum: 500 },
    },
    license: "Estonia",
    payNplay: true,
  },
  {
    name: "powerup",
    type: "bonus",
    img: Powerup,
    link: "https://media1.powerup-partners.com/redirect.aspx?pid=2892&bid=1579",
    perks: {
      freeSpins: { value: 100 },
      wager: { value: 35 },
      bonus: { percentage: 120, maxSum: 120 },
    },
  },

  {
    name: "trickz",
    type: "bonus",
    img: Trickz,
    link: "https://tinyurl.com/Trickzkassu2",
    perks: {
      freeSpins: {
        value: 100,
        condition: "20€ Min. Deposit: 1. depo 100x, 2. depo 200x",
      },
    },
  },
  {
    name: "wisho",
    type: "bonus",
    img: Wisho,
    link: "https://tinyurl.com/wishokassu",
    perks: {
      wager: { value: 30 },
      freeSpins: { value: 200 },
    },
  },
  {
    name: "lataamo",
    type: "bonus",
    img: Lataamo,
    link: "https://tinyurl.com/lataamokassu",
    perks: {
      freeSpins: { value: 100, condition: "20€ deposit" },
    },
  },
  {
    name: "joy",
    type: "bonus",
    img: Joy,
    link: "https://tinyurl.com/win8kassu",
    perks: {
      freeSpins: {
        value: 30,
        condition: "CODE: napsuu, Gates of Olympus min. 5€",
      },
    },
  },
  {
    name: "winnerz",
    type: "bonus",
    img: Winnerz,
    link: "https://tinyurl.com/winnerzkassu",
    perks: {
      wager: { value: 30 },
      freeSpins: { value: 200 },
    },
  },
  {
    name: "friday",
    type: "bonus",
    img: Friday,
    link: "https://tinyurl.com/CasinoFridaykassu",
    perks: {
      freeSpins: { value: 200, condition: "20€ min deposit" },
      bonus: {
        maxSum: 200,
        percentage: 100,
      },
    },
  },
];

export default bonuses;
